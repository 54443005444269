.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F7931A;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wallet-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  margin: 10px;
  margin-bottom: 1rem;
}

.wallet-button {
  padding: 12px 24px;
  border: 1px solid #61dafb;
  border-radius: 4px;
  background: #61dafb;
  color: #282c34;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
  font-weight: 500;
}

.wallet-button:hover {
  background: #4fa8c7;
  border-color: #4fa8c7;
}

.wallet-selector {
  position: relative;
}

.wallet-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 200px;
  background: #282c34;
  border: 1px solid #61dafb;
  border-radius: 4px;
  margin-top: 4px;
  overflow: hidden;
}

.wallet-dropdown button {
  width: 100%;
  padding: 12px;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  text-align: left;
}

.wallet-dropdown button:hover {
  background: rgba(97, 218, 251, 0.1);
}

.wallet-info {
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
}

.disconnect-button {
  padding: 6px 12px;
  border: 1px solid #61dafb;
  border-radius: 4px;
  background: none;
  color: #61dafb;
  cursor: pointer;
}

.disconnect-button:hover {
  background: rgba(97, 218, 251, 0.1);
}

.App-logo-small {
  position: absolute;
  left: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.wallet-info span {
  color: #F7931A;
}

/* If you want to position the mint button separately */
.App-header .wallet-button:not(.wallet-buttons *) {
  margin-top: 20px;  /* Space from other elements */
}

.wizard-content {
  margin-top: -2rem;
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 800px;
}

.wizard-image {
  max-width: 300px;
  height: auto;
}

.wizard-text {
  font-size: 1.1em;
  line-height: 1.5;
  text-align: left;
}

.wizard-text a {
  color: #0066cc;
  text-decoration: none;
}

.wizard-text a:hover {
  text-decoration: underline;
}

.mint-button {
  margin: 2rem 0;
}
